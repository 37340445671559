<template>
    <div class="account">
        <div class="userTitle">
            <span>{{$t('ri-zhi-ji-lu')}}</span>
            <el-date-picker
                class="filter-item"
                v-model="dateRange"
                type="daterange"
                align="right"
                unlink-panels
                :range-separator="$t('to')"
                :start-placeholder="$t('kai-shi-ri-qi')"
                :end-placeholder="$t('jie-shu-ri-qi')"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                refs="picker"
                :clearable="false"
                @change="getList"
                size="small"
            >
            </el-date-picker>

            <el-button
                type="warning"
                size="small"
                style="margin-left:10px"
                :loading="downloading"
                @click="downloadData"
                plain
                >{{$t('dao-chu')}}</el-button
            >

            <div class="flex1"></div>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('shou-ye')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{$t('guan-li-zhong-xin')}}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/accountInfo' }">{{$t('zhang-hu-yu-an-quan')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/account/manage' }">{{$t('quan-xian-guan-li')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('ri-zhi-ji-lu')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="account-table" ref="table">
            <el-table :data="list" row-key="id" :height="tableHeight" size="mini">
                <el-table-column prop="username" :label="$t('yong-hu')"> </el-table-column>
                <el-table-column prop="operate" :label="$t('cao-zuo')"> </el-table-column>
                <el-table-column prop="content" :label="$t('jie-guo')"> </el-table-column>
                <el-table-column prop="time" :label="$t('shi-jian')"> </el-table-column>
                <el-table-column prop="ip" label="ip"> </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { format, parse, startOfDay, endOfDay, getHours, addDays, addMonths } from 'date-fns';
export default {
    name: 'manage',
    data() {
        return {
            list: [],
            loading: false,
            empty: false,
            dateRange: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$t('jin-tian'),
                        onClick(picker) {
                            const date = new Date();
                            picker.$emit('pick', [
                                format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                                format(endOfDay(date), 'yyyy-MM-dd HH:mm:ss')
                            ]);
                        }
                    },
                    {
                        text: this.$t('jin-yi-yue'),
                        onClick(picker) {
                            const date = addMonths(new Date(), -1);
                            picker.$emit('pick', [
                                format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                                format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss')
                            ]);
                        }
                    },
                    {
                        text: this.$t('jin-yi-nian'),
                        onClick(picker) {
                            const date = addMonths(new Date(), -12);
                            picker.$emit('pick', [
                                format(startOfDay(date), 'yyyy-MM-dd HH:mm:ss'),
                                format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss')
                            ]);
                        }
                    }
                ]
            },
            tableHeight: 500,
            downloading: false
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.$refs.table.offsetHeight;
        });
        this.dateRange = [
            format(startOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss'),
            format(endOfDay(new Date()), 'yyyy-MM-dd HH:mm:ss')
        ];
        this.getList();
    },
    methods: {
        getList() {
            this.loading = true;
            this.empty = false;
            this.$http
                .post('/operating/byUser', {
                    userId: this.$route.query.userId,
                    start: this.dateRange[0],
                    end: this.dateRange[1]
                })
                .then(res => {
                    this.list = res;
                    this.loading = false;
                    this.empty = res.length === 0;
                })
                .catch(e => {
                    this.$message.warning(e.error);
                    this.loading = false;
                    this.empty = true;
                });
        },
        getNum(index) {
            if (index < 10) {
                return '0' + index;
            } else {
                return index;
            }
        },
        downloadData() {
            this.downloading = true;
            this.$axios
                .get('/operating/excel', {
                    responseType: 'blob',
                    params: {
                        query: {
                            userId: this.$route.query.userId,
                            time: this.dateRange[0] + ',' + this.dateRange[1]
                        }
                        
                    }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.account {
    display: flex;
    flex-direction: column;
    .account-table {
        flex-grow: 1;
    }
}
.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0;
    border-bottom: 1px solid #bcc1cc;
    margin: 0 20px;
    .el-date-editor {
        margin-left: 40px;
    }
}

.el-table {
    &::before {
        height: 0;
    }
}

.account-table {
    padding: 20px 20px 0;
}
</style>
<style lang="scss">
.account {
    .el-date-editor .el-range-separator {
        color: #bcc1cc;
        .el-range-editor.is-active:hover {
            border-color: #ffa526;
        }
    }
    .el-date-table td.start-date span,
    .el-date-table td.end-date span {
        background-color: #ffa526;
    }
}
</style>
